import { Button, Divider, Group, Pill, ScrollArea } from "@mantine/core";
import { IconArrowRight } from "@tabler/icons-react";
import { useTranslation } from "react-i18next";
import { Link, useRouteLoaderData } from "@remix-run/react";
import { getRoute } from "~/routes";
import { UserVideo } from "./UserVideo";
import { RootLoaderData } from "~/root";
import { useEffect, useState } from "react"; // Adjust the import path as needed

export function RecentUserVideos() {
  const { t } = useTranslation();

  const data = useRouteLoaderData("root") as RootLoaderData;
  const { videos } = data;

  if (videos === undefined || videos.length === 0) {
    return null;
  } else {
    // only display some recent videos
    const videosLimit = 6;
    const isOverVideosLimit = videos.length > videosLimit;

    return (
      <>
        <h2>
          Edit recent{" "}
          {isOverVideosLimit && (
            <Button
              variant="default"
              size="compact-sm"
              component={Link}
              to={getRoute("videos")}
              style={{ marginLeft: 14 }}
              leftSection={<Pill size={"xs"}>{videos.length}</Pill>}
              rightSection={<IconArrowRight size="1rem" />}
            >
              View all videos{" "}
            </Button>
          )}
        </h2>
        <ScrollArea offsetScrollbars type="auto" h={400} pb={"lg"}>
          <Group gap="70px" wrap="nowrap">
            {videos.slice(0, videosLimit).map((videoItem, index) => (
              <UserVideo key={index} videoData={videoItem} />
            ))}
            {isOverVideosLimit && (
              <Button
                variant="transparent"
                size="xs"
                component={Link}
                to={getRoute("videos")}
                style={{ marginLeft: 14 }}
                rightSection={<IconArrowRight size="1rem" />}
              >
                See all {videos.length}
              </Button>
            )}
          </Group>
        </ScrollArea>
        <Divider mb={"100"} mt={"xl"}></Divider>
      </>
    );
  }
}
